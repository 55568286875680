<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  v-if="checkCoca()"
                  src="@/assets/images/logo/coca-black.png"
                  alt="logo"
                />
                <div v-else-if="checkOutHome()"/>
                <b-img
                  v-else
                  :src="logo_brandme"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >

        <div class="p-1" v-if="routePath() && !checkOutHome()">
          <div class="container-search">
            <b-form-input
              class="input-search-proposal"
              :placeholder="user_data && user_data.main_group && (user_data.main_group.name === 'creator') ? $t('creator.placeholder_suggestions_creator') : $t('creator.placeholder_suggestions_brand')"
              v-model="search"
              @keyup.enter="searchQuery()"
              trim
              @focus="focusActionInput"
              @blur="blurActionInput"
              autocomplete="off"
            > 
            </b-form-input>
            <b-icon
              class="icon-search"
              icon="search"
              @click="searchQuery"
            ></b-icon>
            <suggestions-container 
              :show_suggestions="show_suggestions"
              :key="update_suggestions"
              :list_selected="button_Selected"
              :responsive_col="true"
            />
          </div>
        </div>
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg,  BFormInput, BIcon, } from 'bootstrap-vue'
import { provide, computed, ref, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu';
import utils from '@/libs/utils';
import { getUserData } from '@/libs/utils/user';
import suggestionsContainer from '@/views/components/proposals/suggestionsContainer.vue';

export default {
  components: {
    BIcon,
    suggestionsContainer,
    BFormInput,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      search: this.$route.query.query ? this.$route.query.query : '',
      show_suggestions: false,
      update_suggestions: false,
      user_data: getUserData(),
      button_Selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
      rutaDominio: window.location.host,
      logo_brandme: utils.getSkinMode().value === 'dark' ? require('@/assets/images/logo/logo-brandme.png') : require('@/assets/images/logo/logo-brandme1.png'),
      utils,
    }
  },
  created() {
    watch(utils.getSkinMode(), value => {
      if (value === 'dark') this.logo_brandme = require('@/assets/images/logo/logo-brandme.png');
      else this.logo_brandme = require('@/assets/images/logo/logo-brandme1.png');
    });
    this.$root.$on('update_color_button_selected', (selected_Button) => {
      this.button_Selected = selected_Button;
    });
    this.$root.$on('update_search_query', (search) => {
      this.search = search;
    });
  },
  computed: {
    has_a_plan_or_is_creator() {
      if (getUserData() && getUserData().main_group && getUserData().main_group.name === 'creator') return true
      return getUserData() && getUserData().current_plan.name !== 'Free'
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  methods: {
    searchQuery() {
      if (!this.user_data || !this.user_data.main_group || this.user_data.main_group.name !== 'creator') {
        (this.search.length > 0) ? this.$router.push({name: 'search_proposal', query: {query: this.search}}) : this.$router.push({name: 'search_proposal'})
        this.$root.$emit('update_proposals');
      } else if (this.user_data.main_group && this.user_data.main_group.name === 'creator') {
        (this.search.length > 0) ?  this.$router.push({name: 'casting_calls', query: {query: this.search}}) : this.$router.push({name: 'casting_calls'})
        this.$root.$emit('update_casting_calls');
      }
    },
    focusActionInput() {
      if (this.has_a_plan_or_is_creator) {
        this.show_suggestions = true; 
        this.update_suggestions = !this.update_suggestions;
      }
    },
    blurActionInput() {
      if (this.has_a_plan_or_is_creator) {
        setTimeout(() => {        
          this.show_suggestions = false;
          this.update_suggestions = !this.update_suggestions;
        }, 200);
      }
    },
    routePath() {
      if (!this.$route.fullPath.includes('home-search') && !this.$route.fullPath.includes('email-confirm') &&  !this.$route.fullPath.includes('additional-information') && !this.$route.fullPath.includes('/roll')) {
        return true
      }
    },
    checkCoca() {
      return this.rutaDominio.includes('coca')
    },
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style scoped>
.container-search .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 2.4em;
  width: 230px;
  height: 38px;
}
.icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  top: 22px;
  left: 22px;
  font-size: 20px;
  cursor: pointer;
}
</style>

<template>
  <div class="footer-brandme">
    <b-row class="container mt-2 mx-auto" v-if="!checkOutHome()">
      <b-col>
        <h3 class="title">{{ $t("footer.products") }}</h3>
        <ul class="noList">
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/brandme-finder/"
              >{{ $t("footer.conditions") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/software-features/"
              >{{ $t("footer.privacy") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/servicios/"
              >{{ $t("footer.service") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/viralizar-campanas/"
              >{{ $t("footer.campaign") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://shop.brandme.la/"
              >{{ $t("footer.shop") }}</b-link
            >
            <b-link
              class="blink"
              target="_blank"
              href="/leads"
              >{{ $t("footer.leads") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/brandme-talent/"
              >{{ $t("footer.talent") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/brandme-product-placement-patrocinadores/"
              >{{ $t("footer.placement") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/contrata-celebridades/"
              >{{ $t("footer.hire") }}</b-link
            >
          </li>
        </ul>
      </b-col>

      <b-col>
        <h3 class="title">{{ $t("footer.company") }}</h3>
        <ul class="noList">
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="http://bit.ly/ComunidadBrandMe2"
              >{{ $t("footer.comunity") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/empresa/"
              >{{ $t("footer.about") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/casos-de-exito/"
              >{{ $t("footer.casesSuccess") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/prensa/"
              >{{ $t("footer.award") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/carreras/"
              >{{ $t("footer.career") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/contacto/"
              >{{ $t("footer.contact") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/legal/"
              >{{ $t("footer.legal") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/brand-guidelines/"
              >{{ $t("footer.resourceBrand") }}</b-link
            >
          </li>
        </ul>
      </b-col>

      <b-col>
        <h3 class="title">{{ $t("footer.academy") }}</h3>
        <ul class="noList">
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/influencer-marketing/"
              >{{ $t("footer.whatInfluence") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/free-tools/"
              >{{ $t("footer.tools") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/blog/"
              >{{ $t("footer.blog") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/recursos/"
              >{{ $t("footer.resources") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/faq/"
              >{{ $t("footer.support") }}</b-link
            >
          </li>
          <li>
            <b-link
              class="blink"
              target="_blank"
              href="https://brandme.la/faq/"
              >{{ $t("footer.advises") }}</b-link
            >
          </li>
        </ul>
      </b-col>
    </b-row>
    <hr id="hr" />

    <p class="clearfix mb-0 text-white boxNetworks">
      <span class="float-md-left d-block d-md-inline-block mt-25 ml-2">
        <b-img class="img-footer"
          :src="require('@/assets/images/2Crowdmarketing Innovation.png')"
        />
      </span>

      <span class="float-md-right d-none d-md-block">

        <div class="d-flex">
          <b-link
            href="/terminos-y-condiciones"
            target="_blank"
            class="blinkfoot"
          >
            {{ $t("footer.conditions") }}  |
          </b-link>
         
          <b-link
            href="/terminos-y-condiciones"
            target="_blank"
            class="blinkfoot ml-25"
          >
            {{ $t("footer.privacy") }}
          </b-link>
          <b-link
            class="ml-75"
            href="https://www.instagram.com/brandme/"
            target="_blank"
          >
            <span
              class="icomoon-instagram1 instagram blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>
          <b-link
            class="ml-25 twitterIcon"
            href="https://twitter.com/brandme"
            target="_blank"
          >
            <b-img
              src="@/assets/images/icons/twitter-x-black.svg"
              class="blinkfoot block icon-x"
            />
          </b-link>
          <b-link
            href="https://www.facebook.com/brandme"
            target="_blank"
          >
            <span
              class="icomoon-Facebook-2 facebook blinkfoot"
              style="margin: 0 3px; font-size: 1.3em"
            ></span>
          </b-link>
          <b-link
            class="ml-25 linkedinIcon"
            href="https://www.youtube.com/channel/UC-xugPKj2TCraqbTCNnme9w"
            target="_blank"
          >
            <span
              class="icomoon-Youtube-1 youtube blinkfoot youtubeIcon"
            ></span>
          </b-link>

          <b-link
            class="ml-50 linkedinIcon"
            href="https://www.linkedin.com/company/brandme_/"
            target="_blank"
          >
            <span
              class="icomoon-Linkedin linkedin blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>

          <b-link
            class="ml-25"
            href="https://www.tiktok.com/@brandme.la"
            target="_blank"
          >
            <span
              class="icomoon-tiktok instagram blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>
        </div>
        
      </span>
    </p>
  </div>
</template>

<script>
import { BLink, BRow, BCol, BImg } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { watch } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      img_footer: '',
      rutaDominio: window.location.host,
      img_footer_dark_direction: 'brandmeLogo-provicional-dark.png',
      img_footer_direction: 'brandmeLogo-provicional.png'

    };
  },
  created() {
    if (this.checkOutHome()) {
      this.img_footer_dark_direction = 'Logocrowdmarketing_WhiteMorado.png';
      this.img_footer_direction = 'crowdmarketing_logo.png'
    }
  },

  mounted() {
    const { skin } = useAppConfig();
    if (skin.value === 'dark')
      this.img_footer = require(`@/assets/images/logo/${this.img_footer_dark_direction}`);
    else
      this.img_footer = require(`@/assets/images/logo/${this.img_footer_direction}`)
      
    watch(skin , valu => {
      if (valu === 'dark')
        this.img_footer = require(`@/assets/images/logo/${this.img_footer_dark_direction}`);
      else
        this.img_footer = require(`@/assets/images/logo/${this.img_footer_direction}`)
    })
  },
  methods: {
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    }
  }


};
</script>


<style >
.icon-x {
  width:1.2em;
  margin-bottom: 4px;
}
.img-footer{
  max-width: 230px;
  margin-top:-15px
}
.noList {
  list-style: none;
}
.footer-brandme {
  padding-top: 3em;
  padding-bottom: 15px;
}
.blink {
  color: #737373;
  display: block;
  margin-top: 10px;
}
.blink:hover {
  color: #3160f6;
  transition: all 500ms;
}
.title {
  color: #292d38;
  font-size: 26px;
  margin-bottom: 40px;
}
hr {
  color: #d13856 !important;
  margin-bottom: 30px;
}
.icon {
  color: white !important;
}
.blinkfoot {
  color: #737373;
}
.blinkfoot:hover {
  color: #3160f6;
  transition: all 500ms;
}
.icomoon-Youtube-1:before {
  margin: 0 2px 2px 4px;
  font-size: 1.4em !important; 
}
.boxNetworks {
  margin-right: 6em;
}
.twitterIcon {
  margin-top: 1px !important;
}
.linkedinIcon {
  margin-top: 0px !important;
}
</style>
<template>
  <ul id="main-menu-navigation" class="nav navbar-nav item-scroll" :key="change_tab">
    <component :is="resolveNavComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item"
      @changeNavBar="changeNavBarParent" @open_modal="openModal"/>
    
    <modal-membership-section
      :text_selected_dad="text_selected" 
      :open_modal="modal_chat"
      @close_modal="modal_chat = false"
    ></modal-membership-section>
  </ul>
</template>

<script>
import { BImg, BBadge, BCol, BRow, BButton, } from 'bootstrap-vue';
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    BImg,
    BBadge,
    BCol,
    BRow,
    BButton,
    ModalMembershipSection: () => import('@/views/components/modal/ModalMembershipSection.vue')
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      change_tab: false,
      modal_chat: false,
      text_selected: 'search',
    }
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
  methods: {
    changeNavBarParent() {
      this.change_tab = !this.change_tab
    },
    openModal(data) {
      this.text_selected = data.title;
      this.modal_chat = true;
    },
  }
}
</script>
/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from '../horizontal/dashboard'
import buscador from '../horizontal/buscador'
import campaigns from '../horizontal/campaigns'
import ooh_routes from '../horizontal/ooh_routes';
import { isOOH } from '@/libs/utils/user';

// Array of sections
//export default [...dashboard, ...buscador, ...listas]
const array = isOOH() ? [...ooh_routes] : [...dashboard, ...buscador, ...campaigns];
export default array;

<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body" :class="handleMarginBanner()">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getUserData } from '@/libs/utils/user';
import store from '@/store'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  computed: {
    incomplete_payment() {
      const routes_no_alert = ['setting-public-profile', 'home_search', 'additional-information', 'steps_proposal', 'stepper-send-pitch', 'steps_casting_call']
      return store.getters['main_store/incomplete_payment'] && !routes_no_alert.includes(this.$route.name);
    }
  },
  methods: {
    handleMarginBanner() {
      const no_public_profile =  (this.$route.name !== 'home_search' && getUserData() && getUserData().main_group && getUserData().main_group.name === 'creator') && 
      (this.$route.name !== 'setting-public-profile') && (this.metaInfo() === null || this.metaInfo().vanity_name === null || this.metaInfo().birthday === '');
      if (no_public_profile && this.incomplete_payment) {
        return 'margin-custom-both-alerts'
      } else if (no_public_profile && !this.incomplete_payment) {
        return 'mt-6-custom-alert-creator'
      } else if (!no_public_profile && this.incomplete_payment) {
        return 'margin-custom-aler-danger'
      } else return ''
    },
    metaInfo() {
      return getUserData() && getUserData().meta_info
    }
  }
}
</script>

<style lang="scss">
.mt-6-custom-alert-creator {
  margin-top: 6em;
  @media(max-width: 1199px) {
    margin-top: 9em;
  }
  @media(max-width: 761px) {
    margin-top: 11em;
  }
  @media(max-width: 475px) {
    margin-top: 13em;
  }
  @media(max-width: 371px) {
    margin-top: 14.55em;
  }
  @media(max-width: 356px) {
    margin-top: 16em;
  }
}
.margin-custom-aler-danger {
  margin-top: 4.5em;
  @media(max-width: 1199px) {
    margin-top: 6.5em;
  }
  @media(max-width: 583px) {
    margin-top: 8em;
  }
  @media(max-width: 353px) {
    margin-top: 10em;
  }
}

.margin-custom-both-alerts {
  margin-top: 11em;
  @media(max-width: 1199px) {
    margin-top: 14em;
  }
  @media(max-width: 761px) {
    margin-top: 16em;
  }
  @media(max-width: 612px) {
    margin-top: 18em;
  }
  @media(max-width: 475px) {
    margin-top: 20em;
  }
  @media(max-width: 371px) {
    margin-top: 23em;
  }
  @media(max-width: 356px) {
    margin-top: 26em;
  }
}
</style>

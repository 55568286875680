import ability from '@/libs/acl/ability'

export default [
  { 
    id: 1,
    header: 'Campañas ooh',
    img_svg: 'campaign_int_black',
    title: 'campaignsOoh',
    route: 'campaigns',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_campaign'),
    permission: 'view_campaign',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
  {
    header: 'Buscador ubicaciones',
    title: 'searchOoh',
    icon: 'SearchIcon',
    route: 'realty-search',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
  {
    header: 'Maps',
    title: 'map',
    icon: 'MapIcon',
    route: 'realty_maps',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
  {
    title: 'chatOoh',
    icon: 'MessageCircleIcon',
    route: 'chat-proposal',
    action: 'manage',
    resource: 'Dashboard',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
  {
    header: 'Tracking Links',
    icon: 'LinkIcon',
    title: 'tracking_linksOoh',
    route: 'tracking_links',
    resource: 'Dashboard',
    action: 'manage',
    verify_plan: true,
    plan_benefit: 'view_tracking_links',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
]

<template>
  <li
    v-if="canShow(item)"
    class="nav-item"
    :class="` ${{'active': isActive}} ${getActive(item)}`"
    @click="clickNavBar()"
  >
    <b-link
      :class="getClassLink(item)"  
      @click="redirectDisabled(item)"
      v-if="!item.external && notExcluded(item)"
    >
      <feather-icon v-if="item.icon"
        size="14"
        :icon="item.icon"
      />
      <b-img v-else-if="item.img_svg"
        :src="require(`@/assets/images/svg/${item.img_svg}.svg`)"
        class="img-nav"
        :class="{'svg-icon-active': !(getActive(item) === 'noActive' || getActive(item) === '')}"
      />

      <span>{{ $t(`header.menu.${item.title}`) }}</span>
      <b-img
        v-if="getDisabled(item)"
        class="img-crown"
        :src="require(`@/assets/images/svg/crown.svg`)"
      />
      <b-badge class="ml-1" variant="light-primary" v-if="item.newConnect">
        {{$t('connect')}}
      </b-badge>
      <b-badge variant="light-primary" v-if="item.newLabel">
        {{$t('newFeature')}}
      </b-badge>
    </b-link>
    <b-link
      :class="getClassLink(item)"
      :disabled="getDisabled(item)"
      v-if="item.external && notExcluded(item)"
      :href="item.href"
      target="_blank"
    >
      <feather-icon
        size="14"
        :icon="item.icon"
      />
      <span>{{ $t(`header.menu.${item.title}`) }}</span>
      <b-badge class="ml-1" variant="light-primary" v-if="item.newConnect">
        {{$t('connect')}}
      </b-badge>
      <b-badge variant="light-primary" v-if="item.newLabel">
        {{$t('newFeature')}}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
// import { ref } from '@vue/composition-api'
import { BBadge, BLink , BImg} from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { getUserData } from '@/libs/utils/user'

export default {
  components: {
    BImg,
    BBadge,
    BLink,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      role: ''
    }
  },
  created() {
    if (localStorage.userData) {
      const user = JSON.parse(localStorage.userData)
      this.role = (user.groups.length > 0) ? user.groups[0].name : null
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,
      updateIsActive,
    }
  },
  computed: {
    has_session() {
      return getUserData();
    }
  },
  methods: {
    canShow(item) {
      if (this.has_session) return this.$can(item.action, item.resource)
      return item.avaible_with_no_session
    },
    redirectDisabled(item) {
      if (!this.getDisabled(item)) {
        this.$router.push({ name: item.route, params: item.params })
      } else {
        this.$emit('open_modal')
      }
    },
    getDisabled(item) {
      if (item.permission) {
        return !this.$ability.can('manage', item.permission)
      }
      if (item.disabled) return item.disabled
      return false;
    },
    getClassLink(item) {
      if (item.permission) return !this.$ability.can('manage', item.permission) ? 'nav-link item-disabled' : 'nav-link'
      if (item.disabled) return item.disabled ? 'nav-link item-disabled' : 'nav-link';
      return 'nav-link';
    },
    notExcluded(item) {
      if (item.exclude) {
        return item.exclude.indexOf(this.role) === -1
      } else return true
    },
    getActive(item) {
      if (!this.isActive) return 'noActive'
      if (this.$router.currentRoute.name === 'pages-account-setting') {
        if (this.$router.currentRoute.params.section === item.params.section) return 'sidebar-group-active active'
        else return ''
      }
      return this.isActive ? 'sidebar-group-active active' : ''
    },
    clickNavBar() {
      this.$emit('changeNavBar', this.item)
    },
  },

}
</script>

<style type="text/css">
  .aRedirect{
    display: block;
    padding: 10px;
  }
</style>

<style scoped>
.svg-icon-active {
  filter: brightness(0) invert(1);
}
</style>
